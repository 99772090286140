import { useEffect, useState } from 'react';
import apipermissions from '../api/apiweb-userpermissions';

function useUserPermissions() {
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        async function fetchPermissions() {
            try {
                console.log("userpermissions")
                const response = await apipermissions.getPermissions();
                setPermissions(response.data);
             
            } catch (error) {
                console.error('Error fetching permissions', error);
            }
        }

        fetchPermissions();
    }, []);

    return permissions;


    
}

export default useUserPermissions;

import axios from 'axios';
import apiurl from './apiweb.js';
import { fetchAuthSession } from 'aws-amplify/auth'
const reload = {
  reloadCSV: async (file) => {
    
    try {
      const response = await axios({
        method: 'POST',
        url: apiurl + "/reloadCSV", // La ruta de tu endpoint
        headers: {
            'content-type': 'text/csv', // Tipo de contenido
            'x-filename': file.name, 
            'Authorization': "Bearer " + (await fetchAuthSession()).tokens?.idToken?.toString(), // Token de autorización
        },
        data: file,
        crossdomain: true, // Permitir solicitudes entre dominios
    })

      
  if (response.status === 200) {
    console.log("Respuesta del servidor:", response);
   
  } else {
    console.error("Error en la respuesta del servidor:", response);
    
  }
    
    } catch (error) {
      console.error("Error al subir el archivo:", error);
      
    }
  },
};

export default reload;

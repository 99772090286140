import React from 'react';
import { Link } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  //  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { FaSearch, FaCalculator, FaWifi, FaRegUser } from 'react-icons/fa';
import useUserPermissions from './hooks/useUserPermissions';
import './AppMenu.css';
//<MenuItem icon={<FaTachometerAlt />}><Link to="/dashboard">Dashboard</Link></MenuItem>




const AppMenu = ({ collapsed, toggled, handleToggleSidebar, handleCollapsedChange, empresas }) => {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const permissions = useUserPermissions();

  const hasPermission = (permissionName) => {

    return permissions.some(permission => permission.permission_name === permissionName);
  };

  return (
    <ProSidebar
      image={false}
      rtl={false}
      collapsed={false}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          DTE

        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem icon={<FaSearch />}><Link to="/search">Búsqueda</Link></MenuItem>
        </Menu>
        <Menu iconShape="circle">
          <MenuItem icon={<FaCalculator />}><Link to="/resumendiarioboletas">Resumen Diario Boletas</Link></MenuItem>
        </Menu>
        <Menu iconShape="circle">
          <MenuItem icon={<FaCalculator />}><Link to="/resumenmensualboletas">Resumen Mensual</Link></MenuItem>
        </Menu>
        {/* {empresas && empresas!=null && empresas[0] && (empresas[0].rut===1 || 
          empresas[0].rut===78504020 || 
          empresas[0].rut===76791437 || 
          empresas[0].rut===96568320 ||   // Terminal Agropecuario
          empresas[0].rut===96854540 ||   // Urban Park
          empresas[0].rut===76863509 ||   // PLUS PARKING SPA
          empresas[0].rut===79802720      //INMOBILIARIA DEL PACIFICO S.A
          ) && 
        <Menu iconShape="circle">
          <MenuItem icon={<FaCalculator />}><Link to="/cierrecaja">Cierre de Caja</Link></MenuItem>
        </Menu>
        } */}
        {hasPermission('Cierre Caja') && (
          <Menu iconShape="circle">
            <MenuItem icon={<FaCalculator />}><Link to="/cierrecaja">Cierre de Caja</Link></MenuItem>
          </Menu>
        )}
        <Menu iconShape="circle">
          <MenuItem icon={<FaWifi />}><Link to="/estado_cajeros">Estado Cajeros</Link></MenuItem>
        </Menu>
        <Menu iconShape="circle">
          <MenuItem icon={<FaCalculator />}><Link to="/conciliacion">Conciliación</Link></MenuItem>
        </Menu>
        {hasPermission('Sincronizacion SAP') && <Menu iconShape="circle">
          <MenuItem icon={<FaCalculator />}>
            <Link to="/resumenSincScharfstein" className="menu-item">Resumen Sincronización Scharfstein</Link>
          </MenuItem>
        </Menu>}
        {hasPermission('Usuarios POS') &&
          <Menu iconShape="circle">
            <MenuItem icon={<FaRegUser />}><Link to="/usuariosposconfig">Usuarios POS</Link></MenuItem>
          </Menu>}
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <button onClick={signOut} >Salir</button>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default AppMenu;
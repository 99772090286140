import React, { useEffect, useState } from 'react';

import { Card, Button, Modal, Spinner } from 'react-bootstrap';


import { TableSimple } from 'react-pagination-table'; //TablePagination
import "./resumenSinc.scss"

import resumensinc from '../../api/apiweb-resumensinc.js';
import reload from '../../api/apiweb-reloadcsv.js'
import { CSVLink } from "react-csv";

function Resumensinc(props) {

    const [formattedData, setformattedData] = React.useState(null);
    const [modalDescarga, setModalDescarga] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [modalMensaje, setmodalMensaje] = React.useState('');

    const [csvPendientesSinError, setcsvPendientesSinError] = React.useState(null);
    const [csvPendientesConError, setcsvPendientesConError] = React.useState(null);
    const [csvFaltantesbd, setcsvFaltantesbd] = React.useState(null);
    const [file, setFile] = useState(null);
    const hiddenFileInput = React.useRef(null);
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };
    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const closeModal = () => {
        setModalDescarga(false);
    }

    const handleUpload = async () => {
        if (!file) {
            alert("Por favor, selecciona un archivo antes de enviarlo.");
            return;
        }

        setSpinner(true)
        setmodalMensaje('Procesando')
        setModalDescarga(true)
        try {
            const response = await reload.reloadCSV(file);
            console.log("Respuesta del servidor:", response);
            setmodalMensaje('Archivo subido con éxito')
            setSpinner(false)
        } catch (error) {
            setmodalMensaje("Error al subir el archivo")

            console.error("Error al subir el archivo:", error);

        }

    };
    const extractTableData = (data) => {
        return data.map((item) => ({
            fecha: item.fecha,
            pendientesSinError: item.pendientesSinError.cantidad,
            pendientesConError: item.pendientesConError.cantidad,
            faltantesbd: item.faltantes.cantidad
        }));
    };

    const getcsvPendientes = (data) => {
        // Pendientes Sin Error
        const pendientesSinError = data.flatMap((item) =>
            item.pendientesSinError.documentos.map((doc) => ({
                fecha: item.fecha,
                folio: doc.folio,
                ticket: doc.ticket,
            }))
        );

        // Pendientes Con Error
        const pendientesConError = data.flatMap((item) =>
            item.pendientesConError.documentos.map((doc) => ({
                fecha: item.fecha,
                folio: doc.folio,
                ticket: doc.ticket,
                error: doc.error, // Incluye el error si es necesario
            }))
        );

        // Faltantes en BD
        const faltantesbd = data.map((item) => ({
            fecha: item.fecha,
            tickets: item.faltantes.documentos.join(", "), // Agrupa todos los tickets en una sola celda
        }));

        // Actualiza los estados para cada CSV
        setcsvPendientesConError(pendientesConError);
        setcsvPendientesSinError(pendientesSinError);
        setcsvFaltantesbd(faltantesbd);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseData = await resumensinc.getResumenSinc();
                console.log("Datos recibidos del backend:", responseData);

                if (responseData && Array.isArray(responseData.data.data)) {
                    const tableData = extractTableData(responseData.data.data);
                    getcsvPendientes(responseData.data.data)
                    setformattedData(tableData);
                } else {
                    console.error("El formato de los datos no es un arreglo:", responseData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);



    const pendientesSinErrorHeaders = [
        { label: "Fecha", key: "fecha" },
        { label: "Folio", key: "folio" },
        { label: "Ticket", key: "ticket" },

    ];
    const pendientesConErrorHeaders = [
        { label: "Fecha", key: "fecha" },
        { label: "Folio", key: "folio" },
        { label: "Ticket", key: "ticket" },
        { label: "Error", key: "error" },
        { label: "Origen", key: "origen" },
    ];
    const faltantesbdHeader = [
        { label: "Fecha", key: "fecha" },
        { label: "Tickets", key: "tickets" },
    ];
    return (
        <>

            <div className="resumenSinc">
                <div className="d-flex flex-column align-items-start">
                    <Button onClick={handleClick} className="mb-3">
                        Seleccionar archivo CSV
                    </Button>
                    <input
                        type="file"
                        accept=".csv"
                        ref={hiddenFileInput}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                    />
                    <Button onClick={handleUpload} disabled={!file}>
                        Subir archivo
                    </Button>
                </div>
            </div>
            <div className="Search">
                <br />
                <Card>
                    <Card.Header>Subida de archivo</Card.Header>
                    <Card.Body>
                        {file ? (
                            <p>
                                Archivo seleccionado: <strong>{file.name}</strong>
                            </p>
                        ) : (
                            <p>No hay archivo seleccionado.</p>
                        )}
                    </Card.Body>
                </Card>
            </div>
            <div className="Search">
                <br />
                <Card>
                    <Card.Header>Resumen</Card.Header>
                    <Card.Body>
                        {formattedData && formattedData.length > 0 ? (
                            <TableSimple
                                headers={["Fecha", "Pendientes Sin Error", "Pendientes Con Error", "Docs Faltantes en BD"]}
                                data={formattedData}
                                columns="fecha.pendientesSinError.pendientesConError.faltantesbd"
                                perPageItemCount={10}
                                partialPageCount={3}
                                totalCount={formattedData.length}
                                arrayOption={[['size', 'all', ' ']]}
                                nextPageText="Siguiente"
                                prePageText="Anterior"
                            />
                        ) : (
                            <p>No hay datos disponibles para mostrar.</p>
                        )}
                        {csvPendientesSinError && csvPendientesSinError.length > 0 && (
                            <Button variant="primary" style={{ marginTop: "10px" }} className="me-2">
                                <CSVLink
                                    data={csvPendientesSinError}
                                    headers={pendientesSinErrorHeaders}
                                    filename="pendientesSinError.csv"
                                    className="btn btn-primary"
                                >
                                    Descargar Pendientes Sin Error
                                </CSVLink>
                            </Button>
                        )}
                        {csvPendientesConError && csvPendientesConError.length > 0 && (
                            <Button variant="primary" style={{ marginTop: "10px" }} className="me-2">
                                <CSVLink
                                    data={csvPendientesConError}
                                    headers={pendientesConErrorHeaders}
                                    filename="pendientesConError.csv"
                                    className="btn btn-primary"
                                >
                                    Descargar Pendientes Con Error
                                </CSVLink>
                            </Button>
                        )}
                        {csvFaltantesbd && csvFaltantesbd.length > 0 && (
                            <Button variant="primary" style={{ marginTop: "10px" }} className="me-2">
                                <CSVLink
                                    data={csvFaltantesbd}
                                    headers={faltantesbdHeader}
                                    filename="faltantes.csv"
                                    className="btn btn-primary"
                                >
                                    Descargar Faltantes
                                </CSVLink>
                            </Button>
                        )}
                    </Card.Body>
                </Card>
            </div>


            <Modal show={modalDescarga} >
                <Modal.Header>
                    <Modal.Title>Subida Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMensaje}
                    {spinner && <Spinner animation="border" role="status" className="sticky-top"></Spinner>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>


        </>


    );
}


export default Resumensinc;
